<template>
	<div class="dialog_box" v-show="pageShow">
		<div class="content_box">
			<div class="titleBox">
				<div>{{title}}</div>
				<div  @click="closeDialog"><i class="el-icon-close"></i></div>
			</div>
			
			<slot>自定义内容的地方</slot>
			
			<div class="button_box" v-if="showCloseBtn">
				<el-button size="mini" @click="closeDialog">关闭</el-button>
			</div>
		</div>
		
		
	</div>
</template>

<script>
	export default{
		props:{
			showCloseBtn:{
				type: Boolean,
				default:false
			},
			pageShow: {
				type: Boolean
			},
			title:{
				type:String
			}
		},
		data(){
			return{
				
			}
		},
		methods:{
			closeDialog(){
				console.log("111111111111111")
				this.$emit("close")
			}
		}
	}
</script>

<style scoped="">
	.dialog_box{
		position: fixed;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0,0,0,0.5);
		z-index: 4;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.content_box{
		background-color: white;
	}
	.titleBox{
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: 50px;
		padding:0 20px;
		box-sizing: border-box;
		border-bottom: 1px solid #efefef;
	}
	.titleBox>div:nth-of-type(1){
		font-size: 18px;
		margin-right: 30px;
	}
	.titleBox>div:nth-of-type(2){
		color: #909399;
	}
	.titleBox>div:nth-of-type(2):hover{
		cursor: pointer;
		color: #409eff;
	}
	.button_box{
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 10px 0;
	}
	
	
</style>
