
import Vue from 'vue'
import dialog from "../components/dialog.vue"
import eleUiComponents from './ele_ui_components.js'
import getPublic from './public.js'



//对导入的组件进行全局组件注册
Object.keys(eleUiComponents).forEach((key) => {
	if(key == "Message"){
		Vue.prototype.$message = eleUiComponents[key]
	}else{
		Vue.use(eleUiComponents[key])
	}
	
});


Vue.component("myDialog", dialog)

Object.keys(getPublic).forEach((key) => {
  Vue.prototype[key] = getPublic[key];
});

