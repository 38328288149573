// axios拦截器

// import axios from 'axios';
import router from '../route/index'

import {
	Message,
	Loading
} from 'element-ui';
import request from "./request";




const api = function(param) { //url,method,data=null,access_token=null

	param.method = param.method ? param.method : "post";
	let data = param.data ? param.data : null;
	let getFormData = formData(data)
	let url = param.url

	let isLoading = false

	if (param.isLoading) {
		isLoading = param.isLoading
	}
	console.log("param.url=", url, getFormData)

	let headers = {}

	if (param.url !== "userLogin") {
		headers.t_cps_id = localStorage.getItem("t_cps_id");
		headers.t_cps_token = localStorage.getItem("t_cps_token");

	}
	return new Promise((resolve, reject) => {
		let loadingInstance = null
		if (isLoading) {
			loadingInstance = Loading.service({
				lock: true,
				text: '请求中',
				spinner: 'el-icon-loading',
				background: 'rgba(0, 0, 0, 0.7)'
			});
		}
		request.post(url, getFormData).then(res => {
			console.log(res.data)
			if (res.status == 0) {
				if(param.url === "userLogin"){
					localStorage.setItem("t_cps_id", res.data.t_id);
					localStorage.setItem("t_cps_token", res.data.t_cps_token);
					localStorage.setItem("t_user_id", res.data.t_user_id);
					localStorage.setItem("t_channel_name", res.data.t_channel_name);
				}
				resolve(res)
			} else if(res.status == -5){
				Message.error("登陆失效");
				router.push("/login")
			} else {
				Message.error(res.message);
			}

			if (isLoading) {
				loadingInstance.close();
			}

		}).catch(err => {
			reject(err)
			Message.error('出错了，请稍后再试');
			if (isLoading) {
				loadingInstance.close();
			}
			console.log("err",err)

		})


	})

}

const formData = function(params) {
	let formData = new FormData()

	for (let item in params) {
		if (params[item] != 0 && params[item] == '') {
			params[item] = null
		}
		if (params[item] || params[item] == 0) {
			formData.append(item, params[item])
		}
	}
	return formData
}


export default api
