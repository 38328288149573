
// import "babel-polyfill"
import 'core-js/stable'; 
import 'regenerator-runtime/runtime';
import Vue from 'vue';

import './config/index.js';

import router from "./route/index.js"



// import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

// Vue.use(ElementUI);



import App from './App.vue'



Vue.config.productionTip = false




new Vue({
  render: h => h(App),
  router
}).$mount('#app')
